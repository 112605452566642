import { useState } from "react";
import { requestAuth, saveToken } from "../../Functions/Auth";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate()
    const [creds, setCreds] = useState({
        email: "",
        password: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setCreds({
            ...creds,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Corrected method name

        try {
            const response = await requestAuth(creds);
            console.log(response);

            saveToken(response.data.accessToken)
            navigate("/")
            // Handle successful login here, e.g., redirect to another page or display a message
        } catch (error) {
            console.error("Login failed:", error);
            // Handle login errors here, e.g., show an error message to the user
        }
    };

    return (
        <div className="full-screen-container">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 order-md-2">
                        <div className="card card-body logo-box-right text-left">
                            <img src="images/top_right_logo.webp" alt="Top Right Logo" />
                        </div>
                    </div>
                    <div className="col-md-6 order-md-1">
                        <div className="card card-body logo-box-left text-right">
                            <img src="images/top_left_logo.webp" alt="Top Left Logo" />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center form-container">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="top-welcome text-center mb-4">
                                    <h1>Welcome</h1>
                                </div>
                                <form id="loginForm" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="email">Username:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            placeholder="Name"
                                            required
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Employee Code:</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            name="password"
                                            placeholder="Employee Code"
                                            required
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn login-button">Login</button>
                                    </div>
                                </form>
                                <p className="mt-3 text-center text-white">
                                    If you are unable to login, please <a href="https://www.youtube.com/live/b8VliX8xbWA?feature=share" target="__blank">click here</a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
