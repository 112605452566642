/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Loader } from './Components/LoaderComponent';
import Login from './Components/Login';
import HomePage from "./Components/DefaultLayout"


const App = () => {



  return (
    <Router>
      <Suspense fallback={ <Loader />}>
        <Routes>
          <Route
            path="/login"
            element={
              <Login />
            }
          />
          <Route
            path="/*"
            element={
              <HomePage />
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
