import React, { Suspense, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Loader } from "../LoaderComponent";
import { isAuth } from "../../Functions/Auth";



const HomePage = React.lazy(() => import("../Home/index"));
const routes = [
    {
        path: "/",
        component: HomePage,
        module: "Dashboard",
    },

];
const DefaultLayout = () => {

    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuth()) {
            navigate("/login");
        }
    }, [navigate]);

    return (
        <div>
            <Suspense fallback={<Loader />}>
                <Routes>
                    {routes.map((route, index) => (
                        <Route
                            exact
                            key={index}
                            path={route.path}
                            element={<route.component />}
                        />
                    ))}
                </Routes>
            </Suspense>
        </div>
    )

}
export default DefaultLayout